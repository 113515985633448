import ApplicationController from "./application_controller";

//import TomSelect from 'tom-select/dist/esm/tom-select'
import TomSelect from 'tom-select'
import 'tom-select/dist/esm/plugins/remove_button/plugin'
import 'tom-select/dist/esm/plugins/dropdown_input/plugin'
import 'tom-select/dist/css/tom-select.css'
import '../stylesheets/tom-select.scss'

//const selectInput = document.getElementById('applicant_tag_list')

export default class extends ApplicationController {
    static targets = [ "input" ]


    connect() {
        this.initializeTomSelect();
    }

    disconnect() {
        this.destroyTomSelect();
    }


    initializeTomSelect() {
        // Return early if no element is associated with the controller.
        if (!this.element) return;

        const allowCreate = this.inputTarget.dataset.tomselectCreate === 'true'; // Check if the dataset attribute is set to 'true'

        // Fetch options from the select element
        const options = Array.from(this.inputTarget.options).map(option => {
            return {
                value: option.value,
                text: option.text,
                subtext: option.dataset.subtext // Fetch the data-subtext attribute
            };
        });


        let plugins = {};
        if (allowCreate) {
            plugins.remove_button = {
                title: 'Entfernen'
            };
        }

        // if placeholder is set, use it, otherwise use the default
        let placeholder = this.inputTarget.dataset.placeholder ? this.inputTarget.dataset.placeholder: "Suchen";;


        this.tomselect = new TomSelect(this.inputTarget, {
            plugins: plugins,
            persist: false,
            allowEmptyOption: true,
            create: false, // Set create based on the condition
            createFilter: function(input) {
                input = input.toLowerCase();
                return !(input in this.options);
            },
            placeholder: placeholder, //"Bitte auswählen",
            options: options,
            openOnFocus: true, //
            preload: false, //
            closeAfterSelect: true, //
            selectOnTab: true,
            maxOptions: 100, // limit the number of options
            load: (query, callback) => {
                if (!query.length) return callback();
                fetch(`/backend/applicants/query.json?q=${encodeURIComponent(query)}`)
                    .then(response => response.json())
                    .then(data => {
                        callback(data.map(item => ({
                            value: item.id,
                            text: item.firstname + ' ' + item.lastname
                        })));
                    }).catch(() => {
                    callback();
                });
            },
            render: {
                no_results: function (data, escape) {
                    return '<div class="no-results">Keine Ergebnisse für "' + escape(data.input) + '". Entfernen mit der ␛-Taste</div>';
                },
                option_create: function(data, escape) {
                    if (allowCreate) {
                        return '<div class="create"><strong>' + escape(data.input) + '</strong> hinzufügen &hellip;</div>';
                    } else {
                        return '';
                    }
                },
                option: function(data, escape) {
                    return '<div class="option">' +
                        '<span class="title text-sm">' + escape(data.text) + '</span></div>';
                },
                item: function(data, escape) {
                    return '<div class="item text-sm">' + escape(data.text) + '</div>';
                },
                render: {
                    loading_more: function(data, escape) {
                        return `<div class="loading-more-results py-2 d-flex align-items-center"><div class="spinner"></div> Wird geladen</div>`;
                    }
                },
            }
        });
    }



    destroyTomSelect() {
        this.tomselect.destroy(this.inputTarget)
    }
}